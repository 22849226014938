import { AxiosError } from 'axios';
import { del, edit, get } from '@/utils';
import { IRequest } from './types';

async function fetchRequests(scope: 'moderator' | 'fsp'): Promise<IRequest[]> {
	try {
		const res = await get(`api/protected/requests?scope=${scope}`);
		const data = res.data as unknown as { requests: IRequest[] };
		return data?.requests || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa accesam cererile.');
	}
}

async function fetchQueryRequests(query: string): Promise<IRequest[]> {
	try {
		const res = await get(`api/protected/requests${query}`);
		const data = res.data as unknown as { requests: IRequest[] };
		return data?.requests || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa accesam cererile.');
	}
}

async function fetchSingleRequest(requestId: string): Promise<IRequest> {
	try {
		const res = await get(`api/protected/requests/${requestId}`);
		const data = res.data as unknown as { request: IRequest };
		return data?.request || {};
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa accesam cererea.');
	}
}

async function deleteRequest(requestId: string): Promise<string | number> {
	try {
		const res = await del(`api/protected/requests/${requestId}`);
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa stergem cererea.');
	}
}

async function patchRequest(requestId: string, status: 'approved' | 'denied'): Promise<string | number> {
	try {
		const res = await edit(`api/protected/requests/${requestId}`, {status});
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa actualizam cererea.');
	}
}

export { fetchRequests, fetchSingleRequest, deleteRequest, patchRequest, fetchQueryRequests };