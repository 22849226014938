import { useCallback, useMemo, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import { SocketContext } from './context';
import { IChatQuery, SocketContextType } from './types';
import { SOCKET_URL } from './constants';
import { IAttachments } from '@/hooks/fetch-hooks/use-file-uploader/types';

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [socket, setSocket] = useState<Socket>();
  
	const connectSocket = useCallback(() => {
		if (!socket) {
			// If the socket is not initialized, initialize it
			const initiate = io(SOCKET_URL);
			setSocket(initiate);
		} else {
			// If the socket is already initialized, just connect
			socket.connect();
		}
	}, [socket]);

	const disconnectSocket = useCallback(() => {
		socket?.disconnect();
	}, [socket]);

	const authenticateSocket = useCallback((authPayload: {user: string}) => {
		if(socket){
			socket.emit('auth', authPayload);
		}
	}, [socket]);

	const sendMessageToRoom = useCallback((room: string, message: string) => {
		socket?.emit('message-room', { room, message });
	}, [socket]);

	const sendAttachmentsToRoom = useCallback((room: string, attachments: IAttachments[]) => {
		socket?.emit('message-room', { room, attachments });
	}, [socket]);

	const fetchRooms = useCallback(() => {
		socket?.emit('fetch-rooms');
	}, [socket]);

	const joinRoom = useCallback((room: string) => {
		socket?.emit('join-room', { room });
	}, [socket]);

	const leaveRoom = useCallback((room: string) => {
		socket?.emit('leave-room', { room });
	}, [socket]);

	const leaveAllRooms = useCallback(() => {
		socket?.emit('leave-rooms');
	}, [socket]);

	const blockUserEmit = useCallback((messParam: string[]) => {
		socket?.emit('info-message-room', {messages: messParam});
	}, [socket]);

	const deleteEmit = useCallback((message: string) => {
		socket?.emit('deleted-message', {message});
	}, [socket]);

	const deleteProjectEmit = useCallback((project: string) => {
		socket?.emit('deleted-project', {project});
	}, [socket]);

	const addProjectEmit = useCallback((project: string) => {
		socket?.emit('published-project', {project});
	}, [socket]);

	const fetchQueryRooms = useCallback((queryParams: IChatQuery) => {
		socket?.emit('fetch-rooms', {...queryParams});
	}, [socket]);

	const contextValue: SocketContextType = useMemo(() => ({
		socket,
		connectSocket,
		disconnectSocket,
		authenticateSocket,
		fetchRooms,
		joinRoom,
		leaveRoom,
		sendMessageToRoom,
		leaveAllRooms,
		blockUserEmit,
		deleteEmit,
		sendAttachmentsToRoom,
		deleteProjectEmit,
		addProjectEmit,
		fetchQueryRooms
		// eslint-disable-next-line
	}), [
		socket, 
		connectSocket, 
		disconnectSocket, 
		authenticateSocket
	]);
  
	return (
		<SocketContext.Provider value={contextValue}>
			{children}
		</SocketContext.Provider>
	);
};