import { FC, Suspense, useEffect } from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import {
	AuthProvider, ErrorBoundary, NotificationsProvider, StoreProvider,
	useSocketContext
} from './providers';
import { RoutesContainer } from './routes';
import { FiltersProvider } from './providers/filters-provider/provider';

const App: FC = () => {
	const { disconnectSocket } = useSocketContext();

	useEffect(() =>
		// eslint-disable-next-line
		() => {
			disconnectSocket();
		}
		// eslint-disable-next-line
		, []);

	return (
		<Suspense fallback="Loading...">
			<ErrorBoundary>
				<StoreProvider>
					<AuthProvider>
						<NotificationsProvider />
						<BrowserRouter>
							<FiltersProvider>
								<RoutesContainer />
							</FiltersProvider>
						</BrowserRouter>
					</AuthProvider>
				</StoreProvider>
			</ErrorBoundary>
		</Suspense>
	);
};

export default App;
