import { FilterList } from '@mui/icons-material';
import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { utilsActions } from '@/store/utils/slice';
import { useAppDispatch, useAppSelector } from '@/utils';
import { ComplexFilter, SimpleFilter } from './header-filters';
import { HeaderContainer, HeaderContentContainer, HeaderFilterButton } from './styled';
import { UserSide } from './user-side';

export const Header: FC = () => {
	const { isDrawerOpen, isComplexFilterOpen, isSimpleFilterOpen } = useAppSelector((state) => state.utils);
	const { pathname } = useLocation();
	const dispatch = useAppDispatch();

	const showFilters = useMemo(() => {
		const check = pathname.includes('consultation') || pathname.includes('announcment') ||
		pathname.includes('chat');
		return check;
	}, [pathname]);

	const handleOpenFilters = (): void => {
		if ( pathname.includes('chat')) {
			dispatch(utilsActions.toggleSimpleFilter());
		} else if (pathname.includes('consultation') || pathname.includes('announcment')) {
			dispatch(utilsActions.toggleComplexFilter());
		}
	};

	const headerSize: number | undefined = useMemo(() => {
		if (isSimpleFilterOpen && pathname.includes('chat')) {
			return 180;
		} if (isComplexFilterOpen && (
			pathname.includes('consultation') ||pathname.includes('announcment'))
		) {
			return 232;
		} 
		return undefined;
		
	}, [pathname, isComplexFilterOpen, isSimpleFilterOpen]);

	return (
		<HeaderContainer isDrawerOpen={isDrawerOpen} headerSize={headerSize}>
			<HeaderContentContainer hasFilters={showFilters}>
				{showFilters &&
				<HeaderFilterButton size='small' onClick={handleOpenFilters}>
					<FilterList sx={{ mr: 1 }}/>
					filters
				</HeaderFilterButton>
				}
				<UserSide />
			</HeaderContentContainer>

			{isSimpleFilterOpen && pathname.includes('chat') && <SimpleFilter />}
			{isComplexFilterOpen && (
				pathname.includes('consultation') || pathname.includes('announcment')) && <ComplexFilter />
			}
		</HeaderContainer>
	);
};
