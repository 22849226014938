import axios, { AxiosError, AxiosResponse } from 'axios';
import { del, edit, get, post } from '@/utils';
import { CreateSurveyPayload, IConsultation, IUpdateConstulation } from './types';
import { CITIZEN_API_URL } from '@/utils/axios/constants';

const request = axios.create({
	withCredentials: true,
});

async function fetchSurveys(projId: string): Promise<IConsultation[]> {
	try {
		const res = await get(`api/protected/surveys?project=${projId}`);
		const data = res.data as unknown as { surveys: IConsultation[] };
		return data?.surveys || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa accesam consultarile.');
	}
}

async function fetchSurveysQuery(query: string): Promise<IConsultation[]> {
	try {
		const res = await get(`api/protected/surveys${query}`);
		const data = res.data as unknown as { surveys: IConsultation[] };
		return data?.surveys || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa accesam consultatia.');
	}
}

async function fetchSurveysRaports(surveyId: string, type: 'pdf' | 'csv'): Promise<AxiosResponse<Blob>> {
	try {
		const res = await request.get(`${CITIZEN_API_URL}/api/protected/surveys/${surveyId}/${type}`, {
			responseType: 'blob',
		});
		return res;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa accesam rapoartele.');
	}
}

async function fetchSurvey(id: string): Promise<IConsultation> {
	try {
		const res = await get(`api/protected/surveys/${id}`);
		const data = res.data as unknown as { survey: IConsultation };
		return data?.survey || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa accesam consultatia');
	}
}

async function patchSurvey(id: string, updatedObj: IUpdateConstulation): Promise<string | number> {
	try {
		const res = await edit(`api/protected/surveys/${id}`, {...updatedObj});
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa actualizam consultatia');
	}
}

async function deleteSurvey(id: string): Promise<string | number> {
	try {
		const res = await del(`api/protected/surveys/${id}`);
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa stergem consultatia');
	}
}

async function postSurvey(payload: CreateSurveyPayload): Promise<string | number> {
	try {
		const res = await post('api/protected/surveys', {...payload});
		return res?.status === 201 ? 201 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa adaugam consultatia');
	}
}

export { 
	fetchSurveys, patchSurvey, fetchSurveysQuery, deleteSurvey, 
	fetchSurvey, postSurvey, fetchSurveysRaports 
};