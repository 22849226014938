import { useCallback, useEffect, useState } from 'react';
import { ICreateProjectSendData } from '@/providers/create-project/types';
import { notify, stateSetter } from '@/utils';
import { 
	deleteProject, fetchPosterQr, fetchProjects, fetchQueryProjects, 
	fetchSingleProject, patchProject, postProject 
} from './actions';
import { IProject, ProjectsReturnType } from './types';
import { useSocketContext } from '@/providers';

export function useProjects<T extends IProject | IProject[]>(
	getProjectsOnRender: boolean | undefined = undefined,
	idParam: string | undefined = undefined,
	queryParam: string | undefined = undefined
): ProjectsReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingUpdate, setLoadingUpdate] = useState<boolean>(false);
	const { deleteProjectEmit: archiveEmmiter, addProjectEmit } = useSocketContext();
	const [loadingCTA, setLoadingCTA] = useState<boolean>(false);
	const [enableQuery, setEnableQuery] = useState<boolean>(false);

	const getProjects = useCallback(
		async () => {
			try {
				setLoading(true);
				const response = await fetchProjects();
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getQueryProjects = useCallback(
		async (query: string) => {
			try {
				!enableQuery && setEnableQuery(true);
				setLoading(true);
				const response = await fetchQueryProjects(query);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getProject = useCallback(
		async (projId: string) => {
			try {
				setLoading(true);
				const response = await fetchSingleProject(projId);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const createProject = useCallback(
		async (projectObject: ICreateProjectSendData, onSuccess?: () => void) => {
			try {
				setLoadingCTA(true);
				const response = await postProject(projectObject, addProjectEmit);
				if(response === 201) {
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reusit sa adaugam proiectul.');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const updateProject = useCallback(
		async (projId: string, projectObject: Omit<ICreateProjectSendData, 'elements'>, onSuccess?: () => void) => {
			try {
				setLoadingUpdate(true);
				const response = await patchProject(projId, projectObject);
				if(response === 200) {
					if( projectObject.status === 'archived'){
						archiveEmmiter(projId);
					}
					if( projectObject.status === 'published'){
						addProjectEmit(projId);
					}
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reusit sa actualizam proiectul.');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingUpdate(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const removeProject = useCallback(
		async (projectId: string, onSuccess?: () => void) => {
			try {
				setLoading(true);
				const response = await deleteProject(projectId);
				if(response === 200) {
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reusit sa stergem proiectul.');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getProjectPoster = useCallback(
		async (id: string) => {
			try {
				setLoadingCTA(true);
				const response = await fetchPosterQr(id);
				if (response.status !== 200) {
					throw new Error('Nu am reusit sa accesam posterul');
				}
				const fileUrl = window.URL.createObjectURL(response.data);
				window.open(fileUrl, '_blank');
			} catch (error) {
				notify.error(`${(error as Error).message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		[]
	);

	useEffect(() => {
		if (getProjectsOnRender) {
			getProjects();
		}
		// eslint-disable-next-line
	}, [getProjectsOnRender]);

	useEffect(() => {
		if (idParam) {
			getProject(idParam);
		}
		// eslint-disable-next-line
	}, [idParam]);

	useEffect(() => {
		if (queryParam && idParam === undefined) {
			getQueryProjects(queryParam);
		} else if(idParam === undefined && enableQuery) {
			getProjects();
		}
		// eslint-disable-next-line
	}, [queryParam, idParam]);

	return {
		data: data || ([] as IProject[] as T),
		setData: setData as stateSetter<T>,
		loading,
		getProjects,
		createProject,
		getProject,
		removeProject,
		loadingUpdate,
		loadingCTA,
		updateProject,
		getProjectPoster
	};
}