import {
	Autocomplete,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel, Grid,
	MenuItem,
	TextField
} from '@mui/material';
import React from 'react';
import { useCSC } from '@/hooks';
import { HeaderFilterCta } from '../header-filters-cta';
import { initialSimpleFilter, useFiltersContext } from '@/providers/filters-provider/context';

export const SimpleFilterForm: React.FC = () => {
	// const [formState, setFormState] = useState<FormState>(initialState);
	const { simpleFilter, setSimpleFilter, applyFilter, clearFilter } = useFiltersContext();
	const {
		counties, error: errorCounty, loading: loadingCounty,
		cities, errorCity, loadingCity
	} = useCSC(simpleFilter.county?.length > 0 ? simpleFilter.county : undefined);

	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		setSimpleFilter(prevState => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSeen = (value: boolean | null): void => {
		setSimpleFilter(prevState => ({
			...prevState,
			seen: value,
		}));
	};

	const handleAutocompleteChange = (key: 'county' | 'uat', newValue: string): void => {
		setSimpleFilter(prevState => ({
			...prevState,
			[key]: newValue,
		}));
	};

	const handleClear = (): void => {
		setSimpleFilter(initialSimpleFilter);
		clearFilter();
	};

	const handleApply = (): void => {
		applyFilter();
	};

	return (
		<form noValidate autoComplete="off" style={{ width: '100%' }}>
			<Grid container alignItems="center" spacing={2} mb={1}>
				<Grid item xs={12} sm={3}>
					<TextField
						name="search"
						label="Nume proiect"
						variant="outlined"
						value={simpleFilter.search}
						onChange={handleInputChange}
						fullWidth
						placeholder='Cauta aici...'
						size='small'
					/>
				</Grid>
				<Grid item xs={12} sm={2}>
					<TextField
						name="status"
						label="Status proiect"
						variant="outlined"
						value={simpleFilter.status}
						onChange={handleInputChange}
						select
						fullWidth
						placeholder='Cauta aici...'
						size='small'
					>
						<MenuItem value={'published'}>Publicat</MenuItem>
						<MenuItem value={'archived'}>Arhivat</MenuItem>
					</TextField>
				</Grid>
				<Grid item xs={12} sm={2}>
					<FormControl fullWidth variant="standard" size="small">
						<Autocomplete
							options={Object.entries(counties).map(([key]) => key)}
							value={simpleFilter.county}
							onChange={(e, newValue) => handleAutocompleteChange('county', newValue!)}
							onFocus={() => setSimpleFilter((prev) => ({
								...prev,
								uat: ''
							}))}
							disabled={loadingCounty || !!errorCounty}
							renderInput={(params) => (
								<TextField
									{...params}
									label="Judet"
									variant="outlined"
									size='small'
									error={!!errorCounty}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{loadingCounty ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={2}>
					<FormControl fullWidth variant="standard" size="small">
						<Autocomplete
							options={cities}
							value={simpleFilter.uat}
							onChange={(event, newValue) => handleAutocompleteChange('uat', newValue!)}
							disabled={loadingCity || !!errorCity || cities.length === 0}
							renderInput={(params) => (
								<TextField
									{...params}
									label="UAT"
									size='small'
									variant="outlined"
									error={!!errorCity}
									helperText={errorCity ? 'Nu am reusit sa preluam UAt-urile' : ''}
									InputProps={{
										...params.InputProps,
										endAdornment: (
											<>
												{loadingCity ? <CircularProgress color="inherit" size={20} /> : null}
												{params.InputProps.endAdornment}
											</>
										),
									}}
								/>
							)}
						/>
					</FormControl>
				</Grid>
				<Grid item container justifyContent={'flex-end'} gap={1} xs={12} sm={3}>
					<FormControlLabel
						control={<Checkbox name="seen" checked={simpleFilter.seen === true} 
							onChange={() => handleSeen(
								simpleFilter.seen === true ? null : true
							)} />}
						label="Vizualizat"
					/>
					<FormControlLabel
						control={<Checkbox name="seen" checked={simpleFilter.seen === false} 
							onChange={() => handleSeen(
								simpleFilter.seen === false ? null : false
							)} />}
						label="Nevizualizat"
					/>
				</Grid>
			</Grid>
			<HeaderFilterCta onApply={handleApply} onClear={handleClear} />
		</form>
	);
};
