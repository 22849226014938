import { Box, Button, ButtonGroup, CircularProgress, Step, StepButton, Stepper } from '@mui/material';
import { FC } from 'react';
import { stateSetter } from '@/utils';
import { Flex } from '../flex';

interface CustomStepperProps {
	steps: string[];
	activeStep: number;
	setActiveStep: stateSetter<number>;
	onLastStep?: () => void;
	isEditing?: boolean;
	disable: boolean;
	loading: boolean;
}

export const CustomStepper: FC<CustomStepperProps> = ({ 
	activeStep, setActiveStep, steps, onLastStep, isEditing, disable, loading
}) =>{

	const handleNext = (): void => {
		setActiveStep((prev) => prev + 1);
	};

	const handleBack = (): void => {
		setActiveStep((prev) => prev - 1);
	};

	const handleStep = (step: number) => (): void => {
		setActiveStep(step);
	};

	return (
		<Flex sx={{ width: 'calc(100% - 200px)', justifyContent: 'space-between' }}>
			<Box sx={{ width: 'calc(100% - 300px)' }}>
				<Stepper nonLinear activeStep={activeStep}>
					{steps.map((label, index) => (
						<Step key={label} completed={activeStep > index}>
							<StepButton color="inherit" onClick={handleStep(index)}>
								{label}
							</StepButton>
						</Step>
					))}
				</Stepper>
			</Box>
			<Flex>
				<ButtonGroup>
					<Button variant='outlined' disabled={activeStep === 0}
						onClick={handleBack} >
						Initial
					</Button>
					{activeStep !== 2 ?
						<Button variant='contained' disabled={activeStep === 2}
							onClick={handleNext}>
							Urmatorul
						</Button> :
						<Button variant='contained' onClick={onLastStep} disabled={disable || loading} 
							sx={{ minWidth: '160px!important'}}>
							{/* eslint-disable-next-line */}
							{loading ? <CircularProgress sx={{ width: '20px!important', height: '20px!important' }} /> :
								isEditing ? 'Actualizeaza' : 'Creeaza Proiect'
							}
						</Button>
					}
				</ButtonGroup>
			</Flex>
		</Flex>
	);
};

CustomStepper.defaultProps = {
	onLastStep: undefined,
	isEditing: false,
};