import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface UseNavigationBlockerProps {
  shouldBlockNavigation: () => boolean;
}

interface UseNavigationBlockerReturn {
  isBlocking: boolean;
  confirmNavigation: () => void;
  cancelNavigation: () => void;
}

export const useNavigationBlocker = ({
	shouldBlockNavigation,
}: UseNavigationBlockerProps): UseNavigationBlockerReturn => {
	const navigate = useNavigate();
	const [nextLocation, setNextLocation] = useState<string | null>(null);
	const [isBlocking, setIsBlocking] = useState(false);
	// const blocked = useBlocker( shouldBlockNavigation );

	const handleBeforeUnload = useCallback(
		(event: BeforeUnloadEvent) => {
			if (shouldBlockNavigation()) {
				// const temp = blocked.state === 'blocked';
				// setIsBlocking(temp);
				// console.log('temp block', temp);
				window.dispatchEvent(
					new CustomEvent('navigationBlocked', { 
						detail: { 
							show: true 
						} 
					}
					));
				event.preventDefault();
				// eslint-disable-next-line
        // event.returnValue = '';
			}
		},
		[shouldBlockNavigation]
	);

	const handlePopState = useCallback(
		(event: PopStateEvent) => {
			if (shouldBlockNavigation()) {
				event.preventDefault();
				setIsBlocking(true);
				setNextLocation(window.location.pathname);
				window.dispatchEvent(
					new CustomEvent('navigationBlocked', { 
						detail: { 
							show: true 
						} 
					}
					));
			}
		},
		[shouldBlockNavigation]
	);

	useEffect(() => {
		window.addEventListener('beforeunload', handleBeforeUnload);
		window.addEventListener('popstate', handlePopState);
		// eslint-disable-next-line
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
			window.removeEventListener('popstate', handlePopState);
		};
	}, [handleBeforeUnload, handlePopState]);

	const confirmNavigation = (): void => {
		setIsBlocking(false);
		window.dispatchEvent(new CustomEvent('navigationBlocked', { detail: { show: false } }));
		if (nextLocation) {
			navigate(nextLocation, { replace: true });
			setNextLocation(null);
		}
	};

	const cancelNavigation = (): void => {
		setIsBlocking(false);
		window.dispatchEvent(new CustomEvent('navigationBlocked', { detail: { show: false } }));
		setNextLocation(null);
	};

	return { isBlocking, confirmNavigation, cancelNavigation };
};
