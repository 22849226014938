import { 
	Button, Checkbox, CircularProgress, FormControlLabel, 
	FormGroup, Grid, TextField, Typography 
} from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { Flex } from '../flex';
import { SimpleDialogTransition } from '../simple-dialog-transition';
import { IMuteModalProps } from './types';
import { IRestrictObj } from '@/hooks/fetch-hooks';
import { hasEmptyOrNull } from '@/utils';

interface DateTimeInfo {
	dateValue: string;
	timeValue: string;
}
interface FormState {
    dateValue?: string;
    timeValue?: string;
    isInfinite: boolean;
    reason: string;
}

export const MuteModal: FC<IMuteModalProps> = ({
	isMuted,
	userName,
	userId,
	loading,
	onUnmute = (): void => { },
	onMute = (): void => { },
	handleMuteSuccess = (): void => { },
	handleUnmuteSuccess = (): void => { },
}) => {
	const [open, setOpen] = useState(false);
	const [formState, setFormState] = useState<FormState>({
		isInfinite: false,
		reason: '',
	});

	const handleClickOpen = (): void => {
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
		setFormState({
			isInfinite: false,
			reason: '',
		});
	};

	const unmuteSuccess = (): void => {
		handleUnmuteSuccess();
		setOpen(false);
	};

	const handleUnmute = (): void => {
		onUnmute(userId, unmuteSuccess );
	};

	const calculateDuration = (dateTime: DateTimeInfo): number => {
		const dateTimeString = `${dateTime.dateValue} ${dateTime.timeValue}`;
		const dateTimeObj = dayjs(dateTimeString, 'MM/DD/YYYY hh:mm A');
		const timestamp = dateTimeObj.valueOf();
	  
		return timestamp;
	};

	const muteSuccess = (restrictObj: IRestrictObj): void => {
		handleMuteSuccess(restrictObj);
		handleClose();
	};

	const handleMute = (): void => {
		const until = !formState.isInfinite && formState?.dateValue && formState?.timeValue ? calculateDuration({ 
			dateValue: formState?.dateValue, timeValue: formState?.timeValue
		}) : 0;

		const restrictObj: IRestrictObj = {
			reason: formState.reason, 
			until
		};

		onMute(userId, restrictObj, () => muteSuccess(restrictObj));
	};

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const {checked} = event.target;
		setFormState(prevState => {
			if (checked) {
				// eslint-disable-next-line
				const { dateValue, timeValue, ...rest } = prevState;
				return { ...rest, isInfinite: checked };
			}
			return { ...prevState, isInfinite: checked };
		});
	};

	const handleDateChange = (newValue: dayjs.Dayjs | null): void => {
		setFormState(prevState => ({
			...prevState,
			dateValue: newValue ? newValue.format('MM/DD/YYYY') : undefined,
		}));
	};

	const handleTimeChange = (newValue: dayjs.Dayjs | null): void => {
		setFormState(prevState => ({
			...prevState,
			timeValue: newValue ? newValue.format('hh:mm A') : undefined,
		}));
	};

	const handleReasonChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setFormState(prevState => ({
			...prevState,
			reason: event.target.value,
		}));
	};

	const isDisabled = useMemo(() => hasEmptyOrNull(formState), [formState]);

	return isMuted ? (
		<>
			<Button fullWidth variant='outlined' sx={{ height: '42px' }} onClick={handleClickOpen} >
				Derestrictioneaza
			</Button>
			<SimpleDialogTransition open={open} setOpen={setOpen} title={'Derestrictioneaza utilizator'}
				ctaSection={<>
					<Button variant='outlined' onClick={handleClose}>Anuleaza</Button>
					<Button variant='contained' onClick={handleUnmute}>Da</Button>
				</>}
			>
				{loading ? 
					<Flex width={'100%'} sx={{ minWidth: '680px' }} justifyCenter>
						<CircularProgress />
					</Flex> :
					<Flex width={'100%'} sx={{ minWidth: '680px' }} column>
						<Typography fontSize={'24px'}>
							Esti sigur ac doresti sa Derestrictionezi utilizatorul:
						</Typography>
						<Typography fontSize={'34px'}>
							{`${userName}`}
						</Typography>
					</Flex>
				}
			</SimpleDialogTransition>
		</>
	) : (
		<>
			<Button fullWidth variant='outlined' sx={{ height: '42px' }} onClick={handleClickOpen} >
				Restrictioneaza
			</Button>
			<SimpleDialogTransition open={open} setOpen={setOpen} title={'Mute User'}
				ctaSection={<>
					<Button variant='outlined' onClick={handleClose}>Anuleaza</Button>
					<Button variant='contained' disabled={isDisabled} onClick={handleMute}>
						Restrictioneaza
					</Button>
				</>}
			>
				{loading ? 
					<Flex width={'100%'} sx={{ minWidth: '480px' }} justifyCenter>
						<CircularProgress />
					</Flex> :
					<Flex width={'100%'} sx={{ minWidth: '400px', maxWidth: '480px' }} column>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<FormGroup>
										<FormControlLabel
											control={<Checkbox checked={formState.isInfinite} 
												onChange={handleCheckboxChange} />}
											label="Permanent"
										/>
									</FormGroup>
								</Grid>
								<Grid item xs={7}>
									<DatePicker
										label="Data"
										sx={{ width: '100%'}}
										value={formState.dateValue ? dayjs(formState.dateValue, 'MM/DD/YYYY') : null}
										onChange={handleDateChange}
										disabled={formState.isInfinite}
									/>
								</Grid>
								<Grid item xs={5}>
									<TimePicker
										label="Ora"
										value={formState.timeValue ? dayjs(formState.timeValue, 'hh:mm A') : null}
										onChange={handleTimeChange}
										disabled={formState.isInfinite}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										label="Motivul restrictionarii"
										multiline
										maxRows={4}
										value={formState.reason}
										onChange={handleReasonChange}
										fullWidth
									/>
								</Grid>
							</Grid>
						</LocalizationProvider>
					</Flex>
				}
			</SimpleDialogTransition>
		</>
	);
};