import { AxiosError } from 'axios';
import { edit, get, post } from '@/utils';
import { AgrementsType, IAgrements, ICreateAgrements, IUpdateAgrements } from './types';

async function fetchAgrements(type: AgrementsType): Promise<IAgrements[]> {
	try {
		const res = await get(`api/protected/agrements?type=${type}`);
		const data = res.data as unknown as { agrements: IAgrements[] };
		return data?.agrements || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa preluam acordurile.');
	}
}

async function fetchAgrementsQuery(query: string): Promise<IAgrements[]> {
	try {
		const res = await get(`api/protected/agrements${query}`);
		const data = res.data as unknown as { agrements: IAgrements[] };
		return data?.agrements || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa preluam acordurile.');
	}
}

async function fetchSingleAgrement(id: string): Promise<IAgrements> {
	try {
		const res = await get(`api/protected/agrements/${id}`);
		const data = res.data as unknown as { agrement: IAgrements };
		return data?.agrement || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa preluam acordurl.');
	}
}

async function postAgrement(obj: ICreateAgrements): Promise<string | number> {
	try {
		const res = await post('api/protected/agrements', {...obj});
		return res?.status === 201 ? 201 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa cream acordul.');
	}
}

async function patchAgrement(id: string, obj: IUpdateAgrements): Promise<string | number> {
	try {
		const res = await edit(`api/protected/agrements/${id}`, {...obj});
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa actualizam acordul.');
	}
}

export { fetchAgrements, postAgrement, fetchSingleAgrement, fetchAgrementsQuery, patchAgrement };