import { useCallback, useEffect, useState } from 'react';
import { notify, stateSetter } from '@/utils';
import {ConsultationsReturnType, CreateSurveyPayload, IConsultation, IUpdateConstulation } from './types';
import { 
	deleteSurvey,
	fetchSurvey,
	fetchSurveys,
	fetchSurveysQuery,
	fetchSurveysRaports,
	patchSurvey,
	postSurvey, 
} from './actions';

export function useConsultations<T extends IConsultation | IConsultation[]>(
	projectId: string | undefined = undefined,
	parsId: string | undefined = undefined,
	queryParam: string | undefined = undefined
): ConsultationsReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingCTA, setLoadingCTA] = useState<boolean>(false);
	const [enableQuery, setEnableQuery] = useState<boolean>(false);

	const getConsultations = useCallback(
		async (id: string) => {
			try {
				setLoading(true);
				const response = await fetchSurveys(id);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getConsultationsQuery = useCallback(
		async (query: string) => {
			try {
				!enableQuery && setEnableQuery(true);
				setLoading(true);
				const response = await fetchSurveysQuery(query);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getConsultation = useCallback(
		async (id: string) => {
			try {
				setLoading(true);
				const response = await fetchSurvey(id);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const removeConsultation = useCallback(
		async (id: string, onSuccess?: () => void) => {
			try {
				setLoadingCTA(true);
				const response = await deleteSurvey(id);
				if(response === 200) {
					setData(currentData => {
						if (Array.isArray(currentData)) {
							return currentData.filter(surv => surv._id !== id) as T;
						}
						return currentData;
					});
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am resit sa stergem consultatia');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const createConsultation = useCallback(
		async (obj: CreateSurveyPayload, onSuccess?: () => void) => {
			try {
				setLoadingCTA(true);
				const response = await postSurvey(obj);
				if(response === 201) {
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reusit sa adaugam consultatia');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const editConsultation = useCallback(
		async (id: string, updatedObject: IUpdateConstulation, onSuccess?: () => void) => {
			try {
				setLoadingCTA(true);
				const response = await patchSurvey(id, updatedObject);
				if(response === 200) {
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reusit sa actualizam consultatia');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getConsultationRaports = useCallback(
		async (id: string, type: 'pdf' | 'csv') => {
			try {
				setLoadingCTA(true);
				const response = await fetchSurveysRaports(id, type);
				if (response.status !== 200) {
					throw new Error('Nu am reusit sa accesam rapoartele');
				}
				const fileUrl = window.URL.createObjectURL(response.data);
				window.open(fileUrl, '_blank');
			} catch (error) {
				notify.error(`${(error as Error).message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		[]
	);

	useEffect(() => {
		if (projectId) {
			getConsultations(projectId);
		}
		// eslint-disable-next-line
	}, [projectId]);

	useEffect(() => {
		if (parsId) {
			getConsultation(parsId);
		}
		// eslint-disable-next-line
	}, [parsId]);

	useEffect(() => {
		if (queryParam && parsId === undefined && projectId) {
			getConsultationsQuery(queryParam);
		} else if(parsId === undefined && enableQuery && projectId) {
			getConsultations(projectId);
		}
		// eslint-disable-next-line
	}, [queryParam, parsId]);

	return {
		data: data || ([] as IConsultation[] as T),
		setData: setData as stateSetter<T>,
		loading,
		loadingCTA,
		getConsultations,
		getConsultationsQuery,
		getConsultation,
		editConsultation,
		removeConsultation,
		createConsultation,
		getConsultationRaports
	};
}