import { AxiosError } from 'axios';
import { del, edit, get, post } from '@/utils';
import { IProjectType } from './types';

async function fetchTypes(): Promise<IProjectType[]> {
	try {
		const res = await get('api/protected/project-types');
		const data = res.data as unknown as { projectTypes: IProjectType[] };
		return data?.projectTypes || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa accesam tipurile de proiect.');
	}
}

async function deleteType(typeId: string): Promise<string | number> {
	try {
		const res = await del(`api/protected/project-types/${typeId}`);
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa stergem tipul de proiect.');
	}
}

async function postTypes(typeObj: {name: string}): Promise<string | number> {
	try {
		const res = await post('api/protected/project-types', {...typeObj});
		return res?.status === 201 ? 201 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa adaugam tipul de proiect.');
	}
}

async function patchTypes(typeId: string, updateObj: {name: string}): Promise<string | number> {
	try {
		const res = await edit(`api/protected/project-types/${typeId}`, {...updateObj});
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa actualizam tipul de proiect.');
	}
}

export { fetchTypes, deleteType, patchTypes, postTypes };