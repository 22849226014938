import { useCallback, useEffect, useState } from 'react';
import { AgrementsReturnType, AgrementsType, IAgrements, ICreateAgrements, IUpdateAgrements } from './types';
import { notify, stateSetter } from '@/utils';
import { fetchAgrements, fetchAgrementsQuery, fetchSingleAgrement, patchAgrement, postAgrement } from './actions';

export function useAgrements<T extends IAgrements | IAgrements[]>(
	typeParam: AgrementsType | undefined = undefined,
	parsId: string | undefined = undefined,
	queryParam: string | undefined = undefined
): AgrementsReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(true);
	const [loadingCTA, setLoadingCTA] = useState<boolean>(false);

	const getAgrements = useCallback(
		async (type: AgrementsType) => {
			try {
				setLoading(true);
				const response = await fetchAgrements(type);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getAgrementsQuery = useCallback(
		async (query: string) => {
			try {
				setLoading(true);
				const response = await fetchAgrementsQuery(query);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const getAgrement = useCallback(
		async (id: string) => {
			try {
				setLoading(true);
				const response = await fetchSingleAgrement(id);
				setData(response as T);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const createAgrement = useCallback(
		async (obj: ICreateAgrements, onSuccess?: () => void) => {
			try {
				setLoadingCTA(true);
				const response = await postAgrement(obj);
				if(response === 201) {
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reusit sa cream acordul');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const editAgrement = useCallback(
		async (id: string, obj: IUpdateAgrements, onSuccess?: () => void) => {
			try {
				setLoadingCTA(true);
				const response = await patchAgrement(id, obj);
				if(response === 200) {
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reusit sa actualizam acordul');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoadingCTA(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	useEffect(() => {
		if (typeParam) {
			getAgrements(typeParam);
		}
		// eslint-disable-next-line
	}, [typeParam]);

	useEffect(() => {
		if (parsId) {
			getAgrement(parsId);
		}
		// eslint-disable-next-line
	}, [parsId]);

	useEffect(() => {
		if (queryParam) {
			getAgrementsQuery(queryParam);
		}
		// eslint-disable-next-line
	}, [queryParam]);

	return {
		data: data || ([] as IAgrements[] as T),
		setData: setData as stateSetter<T>,
		loading,
		loadingCTA,
		getAgrements,
		createAgrement,
		getAgrement,
		editAgrement
	};
}