import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { FC, Fragment } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuCollapsed } from '../menu-collapsed';
import { useAppSelector, uuid } from '@/utils';
import { menuIcons } from '../data/menuIcons.data';
import { drawerTheme } from '../drawerTheme';

export interface MenuItem {
  title: string;
  icon: string;
  type?: string;
  url: string;
  children?: MenuItem[];
}

interface MenuTabsProps {
  menuItems: MenuItem[];
}

export const MenuTabs: FC<MenuTabsProps> = ({ menuItems }) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const { isDrawerOpen } = useAppSelector((state) => state.utils);

	return (
		<List sx={{ overflow: 'hidden', p: 0, color: '#000' }}>
			{menuItems.map((item) => (
				<Fragment key={uuid()}>
					{item?.type === 'item' ? (
						<ListItem title={!isDrawerOpen ? item.title : ''} disablePadding sx={{ display: 'block' }} 
							onClick={(): void => navigate(item?.url)}>
							<ListItemButton
								disableRipple
								sx={{
									minHeight: 40,
									justifyContent: 'initial',
									backgroundColor: pathname.includes(item?.url) ? drawerTheme.active : 'inherit'
								}}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										justifyContent: 'center',
										mr: 1
									}}
								>
									{menuIcons[item.icon] &&
                    React.cloneElement(menuIcons[item.icon], {
                    	color: pathname.includes(item.url) ? 'primary' : 'inherit',
                    })}
								</ListItemIcon>
								{isDrawerOpen && 
									<ListItemText primary={(item.title).toUpperCase()} sx={{ opacity: 1 }} />
								}
							</ListItemButton>
						</ListItem>
					) : (
						<Fragment key={uuid()}>
							<MenuCollapsed menuTab={item} />
						</Fragment>
					)}
				</Fragment>
			))}
		</List>
	);
};
