import { AxiosError } from 'axios';
import { County } from './types';
import { get } from '@/utils';

async function requestCounties(): Promise<County[]> {
	try {
		const res = await get('api/counties');
		const data = res.data as unknown as { counties: County[] };
		if (data.counties)
			return data.counties;
		throw new Error('Invalid response received!');
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa accesam judetele.');
	}
}

async function requestCities(countyIso: string): Promise<string[]> {
	try {
		const res = await get(`api/cities?iso2=${countyIso}`);
		const data = res.data as unknown as { cities: string[] };
		if (data.cities)
			return data.cities;
		throw new Error('Invalid response received!');
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa accesam orasele.');
	}
}

export { requestCounties, requestCities };
