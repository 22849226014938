import { Button } from '@mui/material';
import { FC } from 'react';
import { Flex } from '../../../flex';

interface IHeaderFilterCta {
    isComplex?: boolean;
	onApply: () => void;
	onClear: () => void;
}

export const HeaderFilterCta: FC<IHeaderFilterCta> = ({ isComplex, onApply, onClear }) => (
	<Flex width={'auto'} pr={isComplex ? 0 : 1} justifyEnd gap={2}>
		<Button size='small' variant='outlined' onClick={onClear}>
			CLEAR FILTER
		</Button>
		<Button size='small' variant='contained' onClick={onApply}>
			APPLY FILTER
		</Button>
	</Flex>
);

HeaderFilterCta.defaultProps = {
	isComplex: false
};