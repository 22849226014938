import { createContext, useContext } from 'react';
import { io, Socket } from 'socket.io-client';
import { SocketContextType } from './types';

const defaultSocket: Socket = io('', {
	autoConnect: false,
});

export const SocketContext = createContext<SocketContextType>({
	connectSocket: () => {},
	disconnectSocket: () => {},
	// setSocket: () => {},
	socket: defaultSocket,
	authenticateSocket: () => {},
	fetchRooms: () => {},
	joinRoom: () => {},
	leaveRoom: () => {},
	sendMessageToRoom: () => {},
	leaveAllRooms: () => {},
	blockUserEmit: () => {},
	deleteEmit: () => {},
	sendAttachmentsToRoom: () => {},
	deleteProjectEmit: () => {},
	addProjectEmit: () => {},
	fetchQueryRooms: () => {}
});

export const useSocketContext = (): SocketContextType => useContext(SocketContext);