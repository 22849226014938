import { createContext, useContext } from 'react';
import { IConsultationsContext } from './types';

export const ConsultationsContext = createContext<IConsultationsContext>({
	loadingProject: true,
	projects: null,
	selectedProject: null,
	setSelectedProject: () => {},
	handleSelectProject: () => {},
	consultation: [],
	setConsultation: () => {},
	loadingCTA: false,
	loadingConsultation: false,
	getConsultationsQuery: () => {},
	handleRemove: () => {},
	setQueryCons: () => {},
});

export const useConsultationsContext = (): IConsultationsContext => useContext(ConsultationsContext);