import { useEffect, useState } from 'react';
import { County, CountiesDictionary, UseCSCReturnType } from './types';
import { requestCities, requestCounties } from './actions';

const getCountiesDictionary = (counties: County[]): CountiesDictionary =>
	counties.reduce((dictionary, county) => {
		// eslint-disable-next-line no-param-reassign
		dictionary[county.name as keyof CountiesDictionary] = county.iso2;
		return dictionary;
	}, {} as CountiesDictionary);

type LoadingErrorState = {
	counties: {
		loading: boolean;
		error: boolean | string;
	};
	cities: {
		loading: boolean;
		error: boolean | string;
	};
	};

export function useCSC(countyParam?: string): UseCSCReturnType {
	const [counties, setCounties] = useState<CountiesDictionary>({});
	const [cities, setCities] = useState<string[]>([]);
	const [status, setStatus] = useState<LoadingErrorState>({
		counties: {
		  loading: false,
		  error: false,
		},
		cities: {
		  loading: false,
		  error: false,
		},
	  });

	const updateCountiesStatus = (loading: boolean, error: boolean | string = false): void => {
		setStatus(prevState => ({
	  ...prevState,
	  counties: {
				loading,
				error,
	  },
		}));
	};
  
	const updateCitiesStatus = (loading: boolean, error: boolean | string = false): void => {
		setStatus(prevState => ({
	  ...prevState,
	  cities: {
				loading,
				error,
	  },
		}));
	};
	useEffect(() => {
		(async (): Promise<void> => {
			updateCountiesStatus(true);
			try {
				const countiesRetrieved = await requestCounties();
				if (countiesRetrieved instanceof Array) {
					setCounties({ '' : '', ...getCountiesDictionary(countiesRetrieved)});
					setCities([]);
				}
				else if (typeof countiesRetrieved === 'string') updateCountiesStatus(true, countiesRetrieved);
				else updateCountiesStatus(true, true);
			} catch (e) {
				if (e instanceof Error) updateCountiesStatus(true, e.message);
				else updateCountiesStatus(true, true);
			} finally {
				updateCountiesStatus(false);
			}
		})();
	}, []);

	useEffect(() => {
		countyParam && counties && (async (): Promise<void> => {
			updateCitiesStatus(true);
			try {
				const countyIso = counties[countyParam];
				if (countyIso){
					const countiesRetrieved = await requestCities(countyIso);
					if (countiesRetrieved instanceof Array) setCities(['', ...countiesRetrieved]);
					else if (typeof countiesRetrieved === 'string') updateCitiesStatus(true, countiesRetrieved);
					else updateCitiesStatus(false, true);
				}
				else updateCitiesStatus(false, true);
			} catch (e) {
				if (e instanceof Error) updateCitiesStatus(true, e.message);
				else updateCitiesStatus(true, true);
			} finally {
				updateCitiesStatus(false);
			}
		})();
		// eslint-disable-next-line
	}, [countyParam, counties]);

	return { 
		counties, 
		cities, 
		error: status.counties.error, 
		loading: status.counties.loading, 
		errorCity: status.cities.error, 
		loadingCity: status.cities.loading 
	};
};