import { AxiosError } from 'axios';
import { IMapProject, IMapQuery } from './types';
import { generateQueryString, get } from '@/utils';

async function fetchMapProjects(queryParam: IMapQuery): Promise<IMapProject[]> {
	const query = generateQueryString(queryParam);
	try {
		const res = await get(`api/protected/map${query}`);
		const data = res.data as unknown as { projects: IMapProject[] };
		return data?.projects || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa accesam proiectele.');
	}
};

export { fetchMapProjects };