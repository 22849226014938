import dayjs from 'dayjs';
import { FC, ReactNode, useMemo, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { notify, useAppDispatch } from '@/utils';
import { IProject, Phase, useProjects } from '@/hooks';
import { CreateProjectContext } from './context';
import { CreateProjectContextType, ICreateProject, ICreateProjectSendData } from './types';
import { projectActions } from '@/store/project/slice';

const initialPhase: Phase = {
	name: 'Faza 1',
	description: '<p>Adauga descriere</p>',
	start: dayjs().valueOf(),
	end: dayjs().valueOf(),
};

const initialObj: ICreateProject = {
	description: '<p>Adauga descriere</p>',
	name: 'Proiect',
	stage: 'in progress',
	status: 'draft',
	type: undefined,
	phases: [initialPhase],
	end: dayjs().valueOf(),
	start: dayjs().valueOf(),
	elements: [],
	gallery: []
};

export const CreateProjectProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const dispatch = useAppDispatch();

	// Extract the projectId from the query parameters
	const searchParams = new URLSearchParams(location.search);
	const projectId = searchParams.get('projectId');

	const { data: projData, createProject, updateProject, 
		loadingUpdate, loadingCTA 
	} = useProjects<IProject>(false, projectId || undefined);
	const isLoading = useMemo(() => loadingUpdate || loadingCTA, [ loadingUpdate, loadingCTA]);

	const initialProject = useMemo(() => {
		if (!projData) {
			return initialObj;
		}
	
		return {
			description: projData.description,
			name: projData.name,
			stage: projData.stage,
			status: projData.status,
			type: projData.type,
			phases: projData.phases,
			end: projData.end,
			start: projData.start,
			elements: projData.elements || [],
			gallery: projData.gallery || []
		};
	}, [projData]);	

	const isEditing: boolean = useMemo(() => !!projectId, [projectId]);
	const [step, setStep] = useState<number>(0);
	const [data, setData] = useState<ICreateProject>(isEditing? initialProject : initialObj);

	const [hasPhases, setHasPhases] = useState<boolean>(false);
	const [isDrawing, setIsDrawing] = useState<boolean>(false);
	// Determine if the current mode is editing

	const disbleEditing = useMemo(() =>
		JSON.stringify(initialProject) === JSON.stringify(data),
	[initialProject, data]);

	useEffect(() => {
		// Ensure we don't overwrite with an identical object
		if (isEditing && JSON.stringify(data) !== JSON.stringify(initialProject)) {
			setData(initialProject);
		}
		// eslint-disable-next-line
	}, [initialProject, isEditing]);

	const handleAddPhase = (): void => {
		setData((prev) => ({
			...prev,
			phases: [...prev?.phases || [], initialPhase]
		}));
	};

	const handleRemovePhase = (index: number): void => {
		setData((prev) => ({
		  ...prev,
		  phases: prev?.phases?.filter((_, i) => i !== index),
		}));
	};

	const onSuccess = (): void => {
		notify.success('Proiect adaugat cu succes!');
		dispatch(projectActions.setId(undefined));
		navigate('/common/project');
	};

	const handleCreate = (): void => {
		const sendData: ICreateProjectSendData = {
			description: data.description,
			end: data.end,
			name: data.name,
			start: data.start,
			status: data.status,
			stage: data.stage,
			type: data?.type?._id,
			elements: data.elements,
			gallery: data.gallery,
			...(data.phases && hasPhases && {
				phases: data.phases
			})
		};
		createProject(sendData, onSuccess);
	};

	const onSuccessUpdate = (): void => {
		notify.success('Proiect actualizat cu succes!');
		dispatch(projectActions.setId(projectId));
		navigate('/common/project');
	};

	const handleEdit = (): void => {
		const sendData: ICreateProjectSendData = {
			description: data.description,
			end: data.end,
			name: data.name,
			start: data.start,
			status: data.status,
			stage: data.stage,
			type: data?.type?._id,
			gallery: data.gallery,
			elements: data.elements,
			...(data.phases && hasPhases && {
				phases: data.phases
			})
		};
		projectId && updateProject(projectId, sendData, onSuccessUpdate);
	};

	const contextValue: CreateProjectContextType = useMemo(() => ({
		step,
		setStep,
		data,
		setData,
		hasPhases,
		setHasPhases,
		isDrawing,
		setIsDrawing,
		handleAddPhase,
		handleRemovePhase,
		handleCreate,
		isEditing,
		handleEdit,
		disbleEditing,
		isLoading
		// eslint-disable-next-line
	}), [step, data, hasPhases, isDrawing, isEditing, setData, disbleEditing, isLoading]);

	return (
		<CreateProjectContext.Provider value={contextValue}>
			{children}
		</CreateProjectContext.Provider>
	);
};
