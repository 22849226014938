import { useCallback, useState } from 'react';
import { notify, stateSetter } from '@/utils';
import { deleteFile, postFile, postFiles } from './actions';
import { FileUploaderReturnType, IAttachments } from './types';

export function useFileUploader<T extends IAttachments[] | string | null>(
): FileUploaderReturnType<T> {
	const [data, setData] = useState<T | null>(null);
	const [loading, setLoading] = useState<boolean>(false);

	const addFiles = useCallback(
		async (file: FileList, onSuccess?: (result: IAttachments[]) => void) => {
			try {
				setLoading(true);
				const response = await postFiles(file);
				if (response?.length > 0){	
					setData(response as T);
					onSuccess && onSuccess(response);
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const addFile = useCallback(
		async (file: File, onSuccess?: (result: string) => void) => {
			try {
				setLoading(true);
				const response = await postFile(file);
				setData(response as T);
				onSuccess && onSuccess(response);
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	const removeFile = useCallback(
		async (file: string, onSuccess?: () => void) => {
			try {
				setLoading(true);
				const response = await deleteFile(file);
				if(response === 200) {
					onSuccess && onSuccess();
				} else if(response === 400) {
					notify.error('Nu am reusit sa stergem fisierul!');
				}
			} catch (message) {
				notify.error(`${message}`);
			} finally {
				setLoading(false);
			}
		},
		// eslint-disable-next-line
		[setData]
	);

	return {
		data: data || ([] as IAttachments[] as T),
		setData: setData as stateSetter<T>,
		loading,
		addFile,
		removeFile,
		addFiles
	};
}