import { AxiosError } from 'axios';
import { del, get, post, edit } from '@/utils';
import { ICreateFSP, IFSP, IUpdateFSP } from './types';

async function fetchFSPs(): Promise<IFSP[]> {
	try {
		const res = await get('api/protected/fsps');
		const data = res.data as unknown as { fsps: IFSP[] };
		return data?.fsps || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa accesam datele.');
	}
}

async function fetchQueryFSPs(query: string): Promise<IFSP[]> {
	try {
		const res = await get(`api/protected/fsps${query}`);
		const data = res.data as unknown as { fsps: IFSP[] };
		return data?.fsps || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa accesam datele.');
	}
}

async function fetchSingleFSP(moderatroId: string): Promise<IFSP> {
	try {
		const res = await get(`api/protected/fsps/${moderatroId}`);
		const data = res.data as unknown as { fsp: IFSP };
		return data?.fsp || {};
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa accesam FSP-ul.');
	}
}

async function deleteFSP(moderatroId: string): Promise<string | number> {
	try {
		const res = await del(`api/protected/fsps/${moderatroId}`);
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa stergem FSP-ul.');
	}
}

async function postFSP(FSPObj: ICreateFSP): Promise<string | number> {
	try {
		const res = await post('api/protected/fsps', {...FSPObj});
		return res?.status === 201 ? 201 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa adaugam FSP-ul.');
	}
}

async function patchFSP(FSPId: string, updatedFSP: IUpdateFSP): Promise<string | number> {
	try {
		const res = await edit(`api/protected/fsps/${FSPId}`, {...updatedFSP});
		return res?.status === 200 ? 200 : 400;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa actualizam FSP-ul.');
	}
}

export { fetchFSPs, fetchSingleFSP, deleteFSP, postFSP, patchFSP, fetchQueryFSPs };
