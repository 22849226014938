import { AxiosError } from 'axios';
import { del, get } from '@/utils';
import { IAppUser } from './types';

async function fetchAppUsers(): Promise<IAppUser[]> {
	try {
		const res = await get('api/protected/users');
		const data = res.data as unknown as { users: IAppUser[] };
		return data?.users || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa accesam datele.');
	}
}

async function fetchQueryUsers(query: string): Promise<IAppUser[]> {
	try {
		const res = await get(`api/protected/users${query}`);
		const data = res.data as unknown as { users: IAppUser[] };
		return data?.users || [];
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa accesam datele.');
	}
}

async function fetchSingleAppUser(userId: string): Promise<IAppUser> {
	try {
		const res = await get(`api/protected/users/${userId}`);
		const data = res.data as unknown as { user: IAppUser };
		return data?.user || {};
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa accesam utilizatorul.');
	}
}

async function deleteUser(userId: string): Promise<string | number> {
	try {
		const res = await del(`api/protected/users/${userId}`);
		return res?.status;
	} catch (e) {
		const error = e as AxiosError;
		const data = error.response?.data as { error: string };
		throw new Error(data?.error || 'Nu am reusit sa stergem utilizatorul.');
	}
}

export { fetchAppUsers, fetchSingleAppUser, deleteUser, fetchQueryUsers };