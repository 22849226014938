import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { ControllConsultationsContext, initialDetails } from './context';
import { 
	ICheckboxQuestion, IControllConsultationsContext,  IDropdownQuestion,  
	ILongQuestion,  IShortQuestion,  ISurveyDetails,  SurveyQuestion, 
	TransformedQuestion
} from './types';
import { useConsultations } from '@/hooks';
import { notify, useAppDispatch, useAppSelector } from '@/utils';
import { CreateSurveyPayload, IUpdateConstulation } from '@/hooks/fetch-hooks/use-consultations/types';
import { surveyActions } from '@/store/survey/slice';

// Initial object for ShortQuestion type
const initialShortQuestion: IShortQuestion = {
	type: 'short',
	id: '',
	question: '',
	htmlQuestion: '<p></p>',
	required: false,
};

// Initial object for LongQuestion type
const initialLongQuestion: ILongQuestion = {
	type: 'long',
	id:  '',
	question: '',
	htmlQuestion: '<p></p>',
	required: false,
};

// Initial object for CheckboxQuestion type
const initialCheckboxQuestion: ICheckboxQuestion = {
	type: 'checkbox',
	id:  '',
	question: '',
	htmlQuestion: '<p></p>',
	required: false,
	options: [],
};

// Initial object for DropdownQuestion type
const initialDropdownQuestion: IDropdownQuestion = {
	type: 'dropdown',
	id:  '',
	question: '',
	htmlQuestion: '<p></p>',
	required: false,
	options: [],
};

export const ControllConsultationsProvider: FC<{ children: ReactNode }> = ({ children }) => {
	const dispatch = useAppDispatch();
	const { survey: storeSurvey} = useAppSelector((state) => state.survey);
	const [survey, setSurvey] = useState<SurveyQuestion[]>(storeSurvey);
	const [selectedItem, setSelectedItem] = useState<SurveyQuestion| null>(null);
	const [details, setDetails] = useState<ISurveyDetails>(initialDetails);
	const [openDetails, setOpenDetails] = useState<boolean>(false);
	const { loadingCTA, createConsultation, editConsultation} = useConsultations();
	const navigate = useNavigate();
	const { consultationProject } = useAppSelector((state) => state.project);
	const [compareObj, setCompareObj] = useState<string>('');

	const addSurvey = (type: 'short' | 'long' | 'checkbox' | 'dropdown'): void => {
		let newQuestion: SurveyQuestion;
		switch (type) {
			case 'short':
				newQuestion = { ...initialShortQuestion, id: `${dayjs().valueOf()}` };
				break;
			case 'long':
				newQuestion = { ...initialLongQuestion, id: `${dayjs().valueOf()}` };
				break;
			case 'checkbox':
				newQuestion = { ...initialCheckboxQuestion, id: `${dayjs().valueOf()}`,
					options: [
						{ value: '', id: `dc-1-${dayjs().valueOf()}`},
						{ value: '', id: `dc-2-${dayjs().valueOf()}`}
					] };
				break;
			case 'dropdown':
				newQuestion = { ...initialDropdownQuestion, id: `${dayjs().valueOf()}`,
					options: [
						{ value: '', id: `dd-1-${dayjs().valueOf()}`},
						{ value: '', id: `dd-2-${dayjs().valueOf()}`}
					] };
				break;
			default:
				throw new Error('Invalid question type');
		}
		setSurvey((prevSurvey) => [...prevSurvey, newQuestion]);
		setSelectedItem(newQuestion);
	};

	const removeSurvey = (id: string): void => {
		selectedItem?.id === id && setSelectedItem(null);
		setSurvey((prevSurvey) => prevSurvey.filter((item) => item.id !== id));
	};
    
	const copyPasteSurvey = (id: string): void => {
		setSurvey((prevSurvey) => {
			const questionToCopy = prevSurvey.find((item) => item.id === id);
			if (questionToCopy) {
				const tempQuest = { ...questionToCopy, id:  `${dayjs().valueOf()}`};
				return [...prevSurvey, tempQuest ];
			}
			return prevSurvey;
		});
	};

	// pars the question id here
	const addOption = (id: string): void => {
		const newOption = { id: `${dayjs().valueOf()}`, value: '' };
		setSurvey((prevSurvey) => {
			const updatedSurvey = prevSurvey.map((item) => {
				if (item.id === id) {
					if (item.type === 'checkbox' || item.type === 'dropdown') {
						const updatedItem = { ...item, options: [...item.options, newOption] };
						if (selectedItem && selectedItem.id === id) {
							setSelectedItem(updatedItem);
						}
						return updatedItem;
					}
				}
				return item;
			});
			return updatedSurvey;
		});
	};

	const editOption = (questionId: string, optionId: string, newValue: string): void => {
		setSurvey((prevSurvey) => {
			const updatedSurvey = prevSurvey.map((item) => {
				if (item.id === questionId) {
					if (item.type === 'checkbox' || item.type === 'dropdown') {
						const updatedOptions = item.options.map((option) =>
							option.id === optionId ? { ...option, value: newValue } : option
						);
						const updatedItem = { ...item, options: updatedOptions };
						if (selectedItem && selectedItem.id === questionId) {
							setSelectedItem(updatedItem);
						}
						return updatedItem;
					}
				}
				return item;
			});
			return updatedSurvey;
		});
	};

	const removeOption = (questionId: string, optionId: string): void => {
		setSurvey((prevSurvey) => {
			const updatedSurvey = prevSurvey.map((item) => {
				if (item.id === questionId) {
					if (item.type === 'checkbox' || item.type === 'dropdown') {
						const updatedOptions = item.options.filter((option) => option.id !== optionId);
						const updatedItem = { ...item, options: updatedOptions };
						if (selectedItem && selectedItem.id === questionId) {
							setSelectedItem(updatedItem);
						}
						return updatedItem;
					}
				}
				return item;
			});
			return updatedSurvey;
		});
	};

	const transformSurveyQuestions = (
		surveyParam: SurveyQuestion[]
	): TransformedQuestion[] => surveyParam.map((question) => {
		if ('options' in question) {
			// eslint-disable-next-line
			const { id, options, ...rest } = question;
			const transformedOptions = options.map((option: { id: string, value: string }) => option.value);
			return {
				...rest,
				options: transformedOptions
			};
		} 
		// eslint-disable-next-line
		const { id, ...rest } = question;
		return rest;
			
	});

	const handleCreateSuccess = (): void => {
		notify.success('Consultatie adaugata cu succes');
		navigate('/common/consultation');
		dispatch(surveyActions.setSurvey([]));
	};

	const handleCreate = (): void => {
		const formatedSurvey = transformSurveyQuestions(survey);
		const sendObj: CreateSurveyPayload = {
			...details,
			survey: formatedSurvey,
			project: consultationProject || '',
		};
		createConsultation(sendObj, handleCreateSuccess);
	};

	const handleEditSuccess = (): void => {
		notify.success('Consultatie actualizata cu succes');
		navigate('/common/consultation');
		dispatch(surveyActions.setSurvey([]));
	};

	const handleEdit = (surveyId: string): void => {
		const formatedSurvey = transformSurveyQuestions(survey);
		const sendObj: IUpdateConstulation = {
			survey: formatedSurvey,
		};
		editConsultation(surveyId ,sendObj, handleEditSuccess);
	};

	const shouldEdit = useMemo(()=> JSON.stringify(survey) !== compareObj,[survey, compareObj]);
	
	useEffect(() => {
		if(survey) {
			dispatch(surveyActions.setSurvey(survey));
		}
		// eslint-disable-next-line
	}, [survey]);

	const value: IControllConsultationsContext = useMemo(() => ({
		survey, 
		setSurvey,
		addSurvey,
		removeSurvey,
		copyPasteSurvey,
		selectedItem,
		setSelectedItem,
		addOption,
		editOption,
		removeOption,
		details,
		setDetails,
		loadingCTA,
		openDetails,
		setOpenDetails,
		handleCreate,
		handleEdit,
		compareObj,
		setCompareObj,
		shouldEdit
		// eslint-disable-next-line
    }),[
		survey, selectedItem, details, loadingCTA, openDetails,
		compareObj, shouldEdit
	]);
    
	return <ControllConsultationsContext.Provider value={value}>
		{children}
	</ControllConsultationsContext.Provider>;
};